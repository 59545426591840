<template>
  <div>
    <div class="benefits rounded dashboard-usertype profile-info">
      <div class="border-bottom d-flex justify-content-between align-items-center pb-2">
        <div>
          <h4 class="section-title">Notification Preference</h4>
        </div>
      </div>

      <div class="mt-6">
        <p>How do you want to receive notifications from Pash?</p>
      </div>

      <b-form-checkbox-group v-model="notification" class="type-wrapper m-auto">
        <div class="item" :class="{ 'border-primary bg-whiteBlue': hasEmail }">
          <b-form-checkbox value="email">Email</b-form-checkbox>
        </div>
        <div class="item" :class="{ 'border-primary bg-whiteBlue': hasSMS }">
          <b-form-checkbox value="sms">SMS</b-form-checkbox>
        </div>
      </b-form-checkbox-group>
    </div>

    <div class="benefits rounded mt-6 dashboard-usertype profile-info">
      <div class="border-bottom d-flex justify-content-between align-items-center pb-2">
        <div>
          <h4 class="section-title">Language</h4>
        </div>
      </div>

      <div class="mt-6">
        <p>Receive all Pash updates and notifications in the language of your choice</p>
      </div>

      <b-dropdown variant="transparents" class="choose-lang">
        <template #button-content
          ><div class="choose-lang-item">
            {{ ChooseLanguage }}
          </div>
        </template>
        <b-dropdown-item @click="ChooseLanguage = 'English '">English</b-dropdown-item>
        <b-dropdown-item @click="ChooseLanguage = 'French '">French</b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notification: [],
      ChooseLanguage: "Choose language ",
    };
  },
  computed: {
    hasEmail() {
      return this.notification.includes("email");
    },
    hasSMS() {
      return this.notification.includes("sms");
    },
  },
};
</script>

<style lang="scss">
.choose-lang {
  &-item {
    font-size: 16px;
    font-weight: 500;
    color: #006ac3 !important;
    font-family: "Inter", sans-serif !important;
  }
  button {
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-top: 8px;
  }
  .dropdown-toggle::after {
    color: #006ac3;
    margin-left: 10px;
    margin-top: 4px;
  }
}
</style>
